import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles, useTheme } from '@material-ui/styles'
import SiteMobileMenu from 'tcweb-material-components/core/siteMenu/mobile'
import DesktopMenu from './components/desktopMenu'
import Icon from 'tcweb-material-components/core/icon'
import Box from 'tcweb-material-components/core/box'
import get from 'tcweb-material-components/core/poly/get'
import loadable from '@loadable/component'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import { Modal } from '@material-ui/core'

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';

import useLogo from '../../hooks/useLogo';
import MenuItem from './components/menuItem'
import SiteMobileMenuItem from './components/siteMobileMenuItem'
import MenuImage from './menuImage'
import FullTableOfContents from '../common/fullTableOfContents'
import SkipMenuButton from 'tcweb-material-components/core/components/skipMenuButton'
import Divider from '@material-ui/core/Divider';
import TEST_IDS from '../../constants/query-selectors'
import brandPortalKeyArtBackground from '../../assets/images/backgrounds/brand-portal/brand_portal_keyart_bg.jpg'

const ProfilePopup = loadable(() => import('./components/profilePopup'))
const PushNotificationButton = loadable(() => import('../common/pushNotificationButton'))

const LOGIN_ENABLED = !!process.env.GATSBY_FEATURE_LOGIN_ON && JSON.parse(process.env.GATSBY_FEATURE_LOGIN_ON.toLowerCase()) === true
const PUSH_NOTIFICATION_ENABLED = !!process.env.GATSBY_FEATURE_PUSH_NOTIFICATION && JSON.parse(process.env.GATSBY_FEATURE_PUSH_NOTIFICATION.toLowerCase()) === true

const useClasses = makeStyles(({ breakpoints, spacing, navigation, palette }) => ({
    logo: {
        height: '40px'
    },
    logoLink: {
        display: 'flex',
        paddingLeft: `${spacing(1)}px`,
        width: 'initial',
        [breakpoints.up('lg')]: { marginRight: '115px' }
    },

    img: {
        marginBottom: '1em',
        borderRadius: '3%'
    },
    icon: {
        paddingBottom: '.1em',
        height: '30px'
    },
    iconTitle: { fontSize: '0.9em' },
    divider: {
        width: '3px',
        backgroundColor: palette.text.primary,
        alignSelf: 'center',
        height: `calc(${navigation.height} / 2)`,
        [breakpoints.up('sm')]:{
            height: `calc(${navigation.tablet.height} / 2)`,
        },
        [breakpoints.up('md')]:{
            height: `calc(${navigation.desktop.height} / 2)`,
        }
    },
    nav: {
        display: 'flex'
    },
    personalized: {
        display: 'flex',
        'flex-direction': 'row',
        'align-items': 'center',
    },
    backdrop: { height: '100%' },
    close: {
        // position: 'absolute', // Give container full height for centering
        padding: `${spacing(2)}px`,
        [breakpoints.up('md')]:{
            padding: `${spacing(2.5)}px`,
        }
    },
    search: {
        display: 'flex',
        alignItems:'center'
    },
    modalHeader: {
        display: 'flex',
        flexDirection: 'row'
    },
    modalBody: {
        overflowY: 'scroll',
        height: `calc(100% - ${navigation.height})`,
        [breakpoints.up('sm')]:{
            height: `calc(100% - ${navigation.tablet.height})`,
        },
        [breakpoints.up('md')]:{
            height: `calc(100% - ${navigation.desktop.height})`,
        },
        background: `url(${brandPortalKeyArtBackground})`,
        backgroundSize: 'cover',
    }
}))

const getItemChildren = item => get(item, 'childItems.nodes')

export default ({ menu: { mobile } }) => {
    const { t } = useTranslation()
    const classes = useClasses()
    const logo = useLogo('brandportal')
    const { breakpoints } = useTheme()
    const isMobile = useMediaQuery(breakpoints.down('xs'))
    const iconSize = isMobile ? '24px' : '30px'
    const [open, setOpen] = useState(false)

    const renderBrand = () => (
        <MenuItem href='/' aria-label={t('slug:go-to-sitename-home', { siteName: process.env.GATSBY_SITE_NAME })} classes={{ root: classes.logoLink }}>
            <img src={logo} alt={t('slug:site-logo', { siteName: process.env.GATSBY_SITE_NAME })} className={classes.logo} />
        </MenuItem>
    )

    const renderMenuItemImage = (item, opts = {}) =>
        <>
            {get(item, 'acf.iconImage')
                ? <MenuImage
                    className={classes.img}
                    fluidImg={get(item, 'acf.iconImage.localFile.childImageSharp.gatsbyImageData')}
                />
                : get(item, 'acf.iconBase', '').toLowerCase() !== 'none' && (
                    <div className={opts.className}>
                        <Icon
                            className={classes.icon}
                            isActive={opts.isActive}
                            iconBase={get(item, 'acf.iconBase', '').toLowerCase()}
                            iconPrefix={get(item, 'acf.iconPrefix', '').toLowerCase()}
                            iconName={get(item, 'acf.iconName', '').toLowerCase()}
                            size='2x'
                        />
                    </div>
                )
            }
            <span className={classes.iconTitle}>{t(get(item, 'label', get(item, 'title')))}</span>
        </>

    const handleClick = () => {
        setOpen(!open)
    }

    const SkipMenuButtonProps = { title: t('Skip Navigation')}

    return (
        <>
            <DesktopMenu>
                <div className={classes.nav}>
                    <IconButton edge='start' aria-label='menu' onClick={handleClick} data-testid={TEST_IDS.headerSite.navigationButton}>
                        <MenuIcon />
                    </IconButton>
                    <Divider className={classes.divider} orientation='vertical' flexItem variant='middle'/>
                    <SkipMenuButton {...SkipMenuButtonProps}/>
                    <Modal
                        open={open}
                        onClose={handleClick}
                        // className={classes.backdrop}
                        BackdropProps= {{
                            style: { backgroundColor: '#070707' }
                        }}
                    >
                        <div className={classes.backdrop}>
                            <div className={classes.modalHeader}>
                                <Button onClick={handleClick} className={classes.close} icon><CloseIcon /></Button>
                                <Divider className={classes.divider} orientation='vertical' flexItem variant='middle'/>
                                <>
                                    {renderBrand()}
                                </>
                            </div>

                            <Box display='flex' alignItems='center' justifyContent='center' height='100%' classes={{root: classes.modalBody}}>
                                <FullTableOfContents />
                            </Box>
                        </div>
                    </Modal>
                    <>
                        {renderBrand()}
                    </>
                    <div className={classes.personalized}>
                        {/* <div className={classes.search}>
                            <SearchIcon />
                            <InputBase
                                placeholder="Search…"
                                // classes={{
                                //     root: classes.inputRoot,
                                //     input: classes.inputInput,
                                // }}
                                // inputProps={{ 'aria-label': 'search' }}
                            />
                        </div> */}
                        <Box display='flex' alignItems='center'>
                            {PUSH_NOTIFICATION_ENABLED && (
                                <PushNotificationButton
                                    marginRight={{ xs: '10px', sm: '20px' }}
                                    FontAwesomeIconProps={{ style: { height: iconSize } }}
                                />
                            )}
                            {/* {LOGIN_ENABLED && <ProfilePopup />} */}
                            {/* <Desktop>
                                <Link variant='contained' fullHeight>
                                    Primary Button
                                </Link>
                            </Desktop> */}
                        </Box>
                    </div>
                </div>

            </DesktopMenu>

            <SiteMobileMenu
                items={mobile}
                renderMenuItemImage={renderMenuItemImage}
                getItemChildren={getItemChildren}
                MenuItemProps={{ component: SiteMobileMenuItem }}
                ButtonProps={{ component: SiteMobileMenuItem }}
                SlideDrawerProps={{
                    MenuItemProps: { component: SiteMobileMenuItem }
                }}
            />
        </>
    )
}
