import React, { useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Location } from '@reach/router'
import grey from '@material-ui/core/colors/grey'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'

import { decodeText } from '../../utilities/helpers'
import Link from '../common/link'
import LocaleContext from '../context/localeContext'

const useStyles = makeStyles((theme) => ({
    link: {
        textTransform: 'initial',
        letterSpacing: 'initial',
        fontStretch: 'initial',
        fontWeight: 'initial',
        fontSize: '0.9em',
        // color: grey[700],
        color: '#c9c9c9',
        fontFamily: 'Segoe UI, SegoeUI, "Helvetica Neue", Helvetica, Arial, sans-serif',
        // '&:hover, &:focus': { color: grey[700] },
        '&:hover, &:focus': { color: '#c9c9c9' },
        [theme.breakpoints.up('md')]: { marginLeft: '1.33em' }
    },
    linksList: {
        fontSize: '0.72em',
        listStyleType: 'none',
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        padding: 0,
        marginTop: '20px',
        marginBottom: '20px',
        flex: 1,
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'space-between'
        },
        [theme.breakpoints.up('sm')]: {
            marginTop: '30px',
            marginBottom: '30px'
        }
    },
    locationPicker: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        color: grey[700],
        marginLeft: 0,
        [theme.breakpoints.down('sm')]: { marginBottom: '30px' },
        [theme.breakpoints.down('xs')]: { marginBottom: '20px' }
    },
    locationPickerContainer: {
        maxWidth: '100%',
        [theme.breakpoints.down('sm')]: {
            width: '100vw'
        },
        [theme.breakpoints.up('md')]: {
            marginRight: 'auto',
            width: 'unset'
        }
    },
    locationPickerIcon: { paddingRight: '.5em' }
}))

const { getLocaleNameFromUrl, removeLocaleFromPath } = require('../../utilities/locale')

const FooterMicrosoft = () => {
    const _classes = useStyles()
    const { t } = useTranslation()
    const localeContext = useContext(LocaleContext)

    return (
        <nav role='navigation' aria-label={t('Microsoft corporate links')}>
            <ul className={_classes.linksList}>
                <li className={_classes.locationPickerContainer}>
                    {/* <Location>
                        {({ location }) =>
                            removeLocaleFromPath(location.pathname) !== '/locales' ? (
                                <Link
                                    classes={{ root: classnames(_classes.locationPicker, _classes.link) }}
                                    href='/locales'
                                    state={{ prevPath: removeLocaleFromPath(location.pathname) }}
                                    aria-label={t('slug:footer-language-selector-button', { lang: getLocaleNameFromUrl(location.pathname) })}
                                >
                                    <FontAwesomeIcon
                                        className={_classes.locationPickerIcon}
                                        icon={['tcr', 'world']}
                                        color={grey[700]}
                                        size='2x'
                                        style={{ height: '1em' }}
                                        fixedWidth
                                    />
                                    {getLocaleNameFromUrl(location.pathname)}
                                </Link>
                            ) : (
                                <span className={_classes.locationPicker}>
                                    <FontAwesomeIcon
                                        className={_classes.locationPickerIcon}
                                        icon={['tcr', 'world']}
                                        color={grey[700]}
                                        title={t('slug:footer-language-selector-button', { lang: getLocaleNameFromUrl(location.pathname) })}
                                        size='2x'
                                        style={{ height: '1em' }}
                                        fixedWidth
                                    />
                                    {getLocaleNameFromUrl(location.pathname)}
                                </span>
                            )
                        }
                    </Location> */}
                </li>
                <li>
                    <Link
                        classes={{ root: _classes.link }}
                        href='https://go.microsoft.com/fwlink/?LinkId=521839'
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        {decodeText(t('slug:privacy-cookies'))}
                    </Link>
                </li>
                <li>
                    <Link classes={{ root: _classes.link }} href='https://www.microsoft.com/trademarks' target='_blank' rel='noopener noreferrer'>
                        {t('Trademarks')}
                    </Link>
                </li>
                <li>
                    <Link
                        classes={{ root: _classes.link }}
                        href='https://www.microsoft.com/servicesagreement'
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        {t('Terms of Use')}
                    </Link>
                </li>
                {localeContext.locale === 'fr-fr' && (
                    <li>
                        <Link
                            classes={{ root: _classes.link }}
                            href='https://go.microsoft.com/fwlink/?linkid=2121428'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            Accessibilité
                        </Link>
                    </li>
                )}
                {localeContext.locale === 'it-it' && (
                    <li>
                        <Link
                            classes={{ root: _classes.link }}
                            href='https://go.microsoft.com/fwlink/?linkid=2121429'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            Accessibilità
                        </Link>
                    </li>
                )}
                <li className={_classes.link}>© Microsoft {new Date().getFullYear()}</li>
            </ul>
        </nav>
    )
}

export default FooterMicrosoft
