import React, { useEffect } from 'react'
import map from 'tcweb-material-components/core/poly/map'
import count from 'tcweb-material-components/core/poly/count'
import get from 'tcweb-material-components/core/poly/get'
import Typography from '@material-ui/core/Typography'

import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'

import classnames from 'classnames'

import PageContext from '../context/pageContext'
import { decodeText } from '../../utilities/helpers'
import useLocation from '../../hooks/useLocation'
import Link from './link'
import FullTocColumn from './fullTocColumn'

const PADDING_LEFT = 20

const useClasses = makeStyles(({ palette, breakpoints }) => ({
    sublist: {
        listStyleType: 'none',
        paddingLeft: 0
    },
    header: { padding: `${PADDING_LEFT}px`},
    subItemLink: {
        width: '100%',
        display: 'block',
        padding: `${PADDING_LEFT}px ${PADDING_LEFT}px ${PADDING_LEFT}px 40px`,
        textTransform: 'capitalize',
        fontSize: '1em', // Override link font size from '1rem'
        justifyContent: 'flex-start',
        color: palette.secondary.contrastText,
        fontWeight: 'normal',
        fontStretch: 'normal',
        '&:hover, &:focus': {
            textDecoration: 'none',
            color: palette.primary.main,
            '&:before': {
                borderLeft: `2px solid ${palette.primary.main}`
            }
        },

        '& div': {
            position: 'relative',

            '&:before': {
                borderLeft: '2px solid transparent',
                height: '100%',
                content: '" "',
                position: 'absolute',
                top: 0,
                transform: `translateX(-${PADDING_LEFT}px)`
            }
        }
    },
    borderedSubItem: {
        position: 'relative',
        '&:before': {
            borderLeft: `2px solid ${palette.border.contrast}`,
            content: '" "',
            position: 'absolute',
            top: 0,
            bottom: 0,
            transform: `translateX(-${PADDING_LEFT}px)`,
            fontSize: '0.89em'
        }
    },
    specialBorderFirst: {
        '&:before': {
            top: `${PADDING_LEFT}px`
        }
    },
    specialBorderLast: {
        '&:before': {
            bottom: `${PADDING_LEFT}px`
        }
    },
    tocColumnContainer: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        [breakpoints.up('sm')]:{
            gridTemplateColumns: 'repeat(4, 1fr)'
        }
    }
}))

const FullTableOfContents = () => {
    const { t } = useTranslation()
    const _classes = useClasses()
    const { location } = useLocation()

    const content = (
        <PageContext.Consumer>
            {context => {
                const items = get(context, 'fullTableOfContents.children', [])

                return (<div className={_classes.tocColumnContainer}>
                    {map(
                        (item, i) => (
                            <FullTocColumn
                                key={i}
                                header={
                                    <Typography variant='h2' className={classnames('h3', _classes.header)}>{decodeText(get(item, 'title', ''))}</Typography>
                                }
                                excerpt={decodeText(get(item, 'excerpt', ''))}
                            >
                                {count(item.children) > 0 && (
                                    <ul className={_classes.sublist}>
                                        {map((subItem, j) => {
                                            const isCurrent = location.hash === subItem.anchor
                                            return (
                                                <li key={j} className={_classes.item}>
                                                    <Link
                                                        aria-label={t('slug:go-to-title', { title: subItem.title })}
                                                        className={classnames(_classes.subItemLink,
                                                            _classes.borderedSubItem, {
                                                            [_classes.selectedSubItemLink]: isCurrent
                                                        })}
                                                        href={`${subItem.path}`}
                                                    >
                                                        <div>{decodeText(get(subItem, 'title', ''))}</div>
                                                    </Link>
                                                </li>
                                            )
                                        }, item.children)}
                                    </ul>
                                )}
                            </FullTocColumn>
                        ),
                    items
                )}
                </div>)
            }}
        </PageContext.Consumer>
    )


    useEffect(() => {
        if (location.hash) {
            const timeoutHandler = setTimeout(() => {
                const anchor = document.querySelector(location.hash)
                const html = document.getElementsByTagName('html')[0]

                if (!anchor) return

                html.classList.toggle('smooth-scroll')
                anchor.focus()
                anchor.scrollIntoView()
                html.classList.toggle('smooth-scroll')
            }, 500)

            return () => {
                clearTimeout(timeoutHandler)
            }
        }
    }, [])

    return content
}

export default FullTableOfContents
