import React from 'react'
import { useState } from 'react'
import Cookie from 'js-cookie'

import { makeStyles } from '@material-ui/core/styles'

import { Checkbox, IconButton, InputAdornment, TextField, Typography } from '@material-ui/core'
import FormGroup from '@material-ui/core/FormGroup'
import Button from 'tcweb-material-components/core/button'
import Section from 'tcweb-material-components/core/section'
import omen from '../../assets/images/omen_splashscreen.jpg'
import logo from '../../assets/images/GoW_Logo.png'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import TEST_IDS from '../../constants/query-selectors'


const useStyles = makeStyles(({ palette }) => ({
  container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flex: '1',
        alignItems: 'center',
        justifyContent: 'center',
        background: `no-repeat center/100% url(${omen}) black`
    },
  passwordContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '0.7em'
    },
  header: {
        textAlign: 'center'
    },
  lockIcon: {
        fontSize: '3.5em'
    },
  submit: {
        backgroundColor: palette.primary.main,
        borderStyle: 'none',
        '&:hover': {
          backgroundColor: palette.primary.light
      }
    },
  form: {
        width:'auto'
    },
    brand: {
        fontSize: '40px',
        color: palette.primary.light,
    },
    logo: {

    },
    field: {
        backgroundColor: palette.border.primary,
        '& .MuiFilledInput-underline:before': {
            borderStyle: 'none'
        }
    },

}))

const AuthGate = ({ setAuth, stateSettings, ...props}) => {
  const classes = useStyles()

  //sets the state of the form to a test if we are testing, otherwise go with default settings
  //if we have statesettings being passed in as part of tests, apply test settings
  const [formState, setFormState] = useState(stateSettings?.test ? stateSettings : {
    password: '',
    success: false,
    formError: false,
    remember: false,
    showPassword: false,
  })

  const AUTHGATE = !!process.env.GATSBY_AUTHGATE ? process.env.GATSBY_AUTHGATE : `${props.location.origin}/api/AuthGateBackend`

  const authorize = () => {
    Cookie.set('authorization','test',{expires: formState.remember ? 7 : 1})
    setAuth(true)
  }


  const authCheck = Cookie.get('authorization')

  const capsCheck = (keyEvent) => {
    if (keyEvent.getModifierState("CapsLock")) {
      setFormState({...formState, formError: 'Caps lock is on'})
    } else {
      setFormState({...formState, formError: false})
    }
  }

  const handleCheck = (e) => { setFormState({...formState, remember: e.target.checked})}

  const handleClickShowPassword = (e) => {setFormState({...formState, showPassword: !formState.showPassword})}

  const handleMouseDownPassword = (e) => {setFormState({...formState, showPassword: !formState.showPassword})}

  async function sendAuth(e) {
    e.preventDefault();
    try {
        const data = await (
            fetch(`${AUTHGATE}`, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ name: formState.password })
            }).then(res => res.json())
            .then(data => {
                if (data.success) {
                    setFormState({...formState, success: true})
                    authorize()
                } else {
                    setFormState({...formState, formError: 'Something went wrong'})
                }
            })
        )
        return data
    } catch (error) {
      setFormState({...formState, formError: 'Something went wrong'})
    }
  }

  if (authCheck) {
    // setAuth(true)

    return (
        <></>
    )
  } else {

  }


  return (
    <>
      {authCheck ? null :
          <div className={classes.container}>
              {formState.success ? 'Success!' :
                  <Section component='form' onSubmit={sendAuth} noValidate className={classes.form}>
                  <div className={classes.passwordContainer}>
                      <div>
                        <img className={classes.logo} src={logo}/>
                        <div className={classes.header}>
                            <Typography variant="h1" className={classes.brand}>BRAND PORTAL</Typography>
                        </div>
                      </div>

                      <FormGroup>
                            <Typography>Please enter a password to view this site</Typography>
                            <TextField
                                className={classes.field}
                                label='Password'
                                variant='filled'
                                required
                                data-testid={TEST_IDS.authGate.inputField}
                                type={formState.showPassword ? "text" : "password"}
                                onChange={(e) => {setFormState({...formState, password: e.target.value})}}
                                onKeyDown={(e) => {capsCheck(e)}}
                                error={formState.formError}
                                helperText={typeof formState.formError === 'boolean' && formState.formError === true ? 'Something went wrong!' : null}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        data-testid={TEST_IDS.authGate.visibility}
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                      >
                                        {formState.showPassword ? <Visibility data-testid={TEST_IDS.authGate.visibilityOn}/> : <VisibilityOff data-testid={TEST_IDS.authGate.visibilityOff}/>}
                                      </IconButton>
                                    </InputAdornment>
                                  )
                                }}
                            />
                      </FormGroup>
                      <div>
                        <Checkbox
                          checked={formState.remember}
                          onChange={handleCheck}
                          color="primary"
                        />
                        Remember me
                      </div>
                      <Button
                              variant='contained'
                              type='submit'
                              className={classes.submit}
                              onClick={sendAuth}
                          >
                              Submit
                          </Button>
                  </div>
                  </Section>
              }
          </div>
      }
    </>
  )
}

export default AuthGate